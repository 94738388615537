import styles from "./ReportPage.module.scss";

import ContentArea, {
  ContentAreaProps,
} from "components/utils/ContentArea/ContentArea";
import ReportPageHeroBlock from "components/blocks/ReportPageHeroBlock/ReportPageHeroBlock";
import { ReportPageHeroBlockProps } from "components/blocks/ReportPageHeroBlock/ReportPageHeroBlock";
import TopPageBlocks, {
  TopPageBlocksProps,
} from "components/shared/TopPageBlocks/TopPageBlocks";
import CardListBlock, {
  CardListBlockProps,
} from "components/blocks/CardListBlock/CardListBlock";
import ContactBlockItem, {
  ContactBlockItemProps,
} from "components/blocks/ContactBlock/ContactBlockItem/ContactBlockItem";
import { GridItemOffsetContext } from "src/utils/GridItemOffsetContext";
import { BlockContainer, GridContainer, GridItem } from "SiteComponents";

export interface ReportPageProps extends TopPageBlocksProps {
  contentArea: ContentAreaProps;
  contactBlockItem?: ContactBlockItemProps;
  hero: ReportPageHeroBlockProps;
  relatedReports?: CardListBlockProps;
}

const ReportPage: React.FC<ReportPageProps> = ({
  contentArea,
  contactBlockItem,
  hero,
  alert,
  mainIntro,
  relatedReports,
}) => {
  return (
    <main className={styles.reportPage}>
      {hero && <ReportPageHeroBlock {...hero} />}

      <div className={styles.fixedBlocks}>
        <div className={styles.main}></div>
        {contactBlockItem && (
          <ContactBlockItem className={styles.aside} {...contactBlockItem} />
        )}
      </div>

      {contentArea && (
        <GridItemOffsetContext.Provider value={2}>
          <TopPageBlocks
            width="withAside"
            alert={alert}
            mainIntro={mainIntro}
          />
          <ContentArea className={styles.main} {...contentArea} />
        </GridItemOffsetContext.Provider>
      )}
      {relatedReports && (
        <GridContainer>
          <GridItem>
            <CardListBlock {...relatedReports} />
          </GridItem>
        </GridContainer>
      )}
    </main>
  );
};

export default ReportPage;
