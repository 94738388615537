import React from "react";
import styles from "./ResultsPageHeroBlock.module.scss";
import BlockContainer from "components/layout/BlockContainer/BlockContainer";
import { ReactComponentPropsBase } from "../../../base-props/ReactComponentPropsBase";

export interface ResultsPageHeroBlockProps extends ReactComponentPropsBase {
  heading: string;
  mainIntro: string;
}

const ResultsPageHeroBlock: React.FC<ResultsPageHeroBlockProps> = ({
  heading,
  mainIntro,
}) => {
  return (
    <BlockContainer className={styles.resultsPageHeroBlock}>
      <h1 className={styles.heading}>{heading}</h1>
      <p className={styles.ingress}>{mainIntro}</p>
    </BlockContainer>
  );
};

export default ResultsPageHeroBlock;
