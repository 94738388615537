import React, { useEffect, useState } from "react";
import styles from "./ContentManagerBlock.module.scss";
import { ReactComponentPropsBase } from "../../../base-props/ReactComponentPropsBase";

import { FilterVerticalProps } from "./FilterBlock/FilterVertical/FilterVertical";

import FilterBlock, { FilterBlockProps } from "./FilterBlock/FilterBlock";
import { CardList, PaginationBlock, Tabs } from "SiteComponents";
import { TabsProps } from "components/utils/Tabs/Tabs";
import { CardListProps } from "components/shared/CardList/CardList";
import { SearchHitInfo } from "../SearchBlock/SearchInput/SearchInput";
import { Filter } from "./FilterBlock/FilterVertical/FilterList";
import { PaginationBlockProps } from "../PaginationBlock/PaginationBlock";
import useFetchHelper, { GenericParams } from "src/utils/useFetchHelper";

interface Response {
  panelData: CardListProps;
  totalCount?: number;
  query?: string;
  filterGroupList?: FilterVerticalProps[];
  labels?: {
    closeFilter?: string;
  };
  pagination: PaginationBlockProps;
}

interface Params {
  q?: string;
  tab?: string;
  sort?: string;
  page?: string;
  resultsType?: string;
  siteType?: string;
}
export interface ContentManagerBlockProps extends ReactComponentPropsBase {
  filterBlock: FilterBlockProps;
  filterGroupList: FilterVerticalProps[];
  tabs: TabsProps;
  results?: CardListProps;
  endpoint: string;
  searchHitInfo?: SearchHitInfo;
  totalCount?: number;
  resultsType?: string;
  siteType?: string;
  pagination?: PaginationBlockProps;
}

const ContentManagerBlock: React.FC<ContentManagerBlockProps> = ({
  filterBlock,
  filterGroupList,
  tabs,
  results,
  endpoint,
  searchHitInfo,
  totalCount,
  resultsType,
  siteType,
  pagination
}) => {
  const [filterState, setFilterState] =
    useState<FilterVerticalProps[]>(filterGroupList);
  const [tabPanelData, setTabPanelData] = useState<CardListProps | undefined>(
    results
  );
  const [totalCountState, setTotalCountState] = useState<number | undefined>(
    totalCount
  );
  const [queryParams, setQueryParams] = useState<Params>({
    tab: tabs.defaultActiveTab || tabs.tabList[0]?.id || "",
    resultsType: resultsType,
    siteType: siteType
  });
  const [filterBlockLabels, setFilterBlockLabels] = useState(
    filterBlock.labels
  );
  const [paginationState, setPaginationState] = useState(pagination || {});

  const [didMount, setDidMount] = useState(false);

  const { isLoading, fetchData } = useFetchHelper();

  const fetchResults = async () => {
    try {
      const data: Response = await fetchData(
        endpoint,
        queryParams as GenericParams
      );
      setTabPanelData(data.panelData);
      setTotalCountState(data.totalCount);
      setFilterBlockLabels({ ...filterBlockLabels, ...data.labels });

      if (data.filterGroupList) {
        setFilterState(data.filterGroupList);
      }
      if (data.pagination) {
        setPaginationState({ ...paginationState, ...data.pagination });
      }
    } catch (error) {
      console.log("Error fetching results:", error);
    }
  };

  const handleTabChange = (id: string) => {
    setQueryParams({ ...queryParams, tab: id });
  };

  const handleFilterChange = (filterState: FilterVerticalProps[]) => {
    setFilterState(filterState);
    const filterQueryParameters: { [key: string]: string } = (() => {
      const filterWithArrays: { [key: string]: string[] } = filterState.reduce(
        (acc, filterGroup) => {
          (function extractFilters(filters: Filter[]) {
            filters.forEach(filter => {
              if (filter.checked) {
                if (!acc[filterGroup.id]) acc[filterGroup.id] = [];
                acc[filterGroup.id].push(filter.id);
              }
              if (filter.nestedFilters) extractFilters(filter.nestedFilters);
            });
          })(filterGroup.filters);

          return acc;
        },
        {} as { [key: string]: string[] }
      );

      const filterQueryParameters: { [key: string]: string } = Object.entries(
        filterWithArrays
      ).reduce((acc, [key, value]) => {
        return {
          ...acc,
          [key]: value.join(","),
        };
      }, {});

      return filterQueryParameters;
    })();

    setQueryParams({ ...queryParams, ...filterQueryParameters });
  };

  const handlePaginationChange = (page: number) => {
    setPaginationState({ ...paginationState, currentPage: page });
    setQueryParams({ ...queryParams, page: page.toString() });
  };

  useEffect(() => {
    if (!didMount) {
      !tabPanelData && fetchResults();
      setDidMount(true);
    } else {
      fetchResults();
    }
  }, [queryParams]);

  return (
    <div className={styles.contentManagerBlock}>
      <div className={styles.leftColumn}>
        <div className={styles.searchHitInfo}>
          {searchHitInfo && totalCount && (
            <>
              {searchHitInfo.firstPart}&nbsp;
              {totalCountState} &nbsp;
              {searchHitInfo.lastPart}
            </>
          )}
        </div>

        {filterBlock && (
          <FilterBlock
            {...filterBlock}
            labels={filterBlockLabels}
            filterState={filterState}
            onFilterChange={handleFilterChange}
          />
        )}
      </div>
      <div className={styles.rightColumn}>
        {tabs && (
          <Tabs
            {...tabs}
            onTabChange={handleTabChange}
            PanelComponent={CardList}
            panelComponentProps={tabPanelData}
            isLoading={isLoading}
          />
        )}
        {paginationState && (
          <PaginationBlock
            onChange={handlePaginationChange}
            {...paginationState}
          />
        )}
      </div>
    </div>
  );
};

export default ContentManagerBlock;
