import classNames from "classnames";
import styles from "./EventPage.module.scss";

import AccordionItemBlock, {
  AccordionItemBlockProps,
} from "components/blocks/AccordionItemBlock/AccordionItemBlock";
import ContactBlockItem, {
  ContactBlockItemProps,
} from "components/blocks/ContactBlock/ContactBlockItem/ContactBlockItem";
import EventPageHeroBlock, {
  EventPageHeroBlockProps,
} from "components/blocks/EventPageHeroBlock/EventPageHeroBlock";
import HighlightedInfoBlock, {
  HighlightedInfoBlockProps,
} from "components/blocks/HighlightedInfoBlock/HighlightedInfoBlock";
import ContentArea, {
  ContentAreaProps,
} from "components/utils/ContentArea/ContentArea";
import { GridItemOffsetContext } from "src/utils/GridItemOffsetContext";
import ContactBlock, { ContactBlockProps } from "components/blocks/ContactBlock/ContactBlock";

export interface EventPageProps {
  contentArea: ContentAreaProps;
  mainIntro?: string;
  highlightedInfo?: HighlightedInfoBlockProps;
  contactBlockItem?: ContactBlockItemProps;
  hero: EventPageHeroBlockProps;
  program?: AccordionItemBlockProps;
  contributors?: ContactBlockProps;
}

const EventPage: React.FC<EventPageProps> = ({
  contentArea,
  hero,
  contactBlockItem,
  mainIntro,
  highlightedInfo,
  program,
  contributors
}) => {
  return (
    <main className={styles.eventPage}>
      {hero && <EventPageHeroBlock {...hero} />}
      <div
        className={classNames(styles.fixedBlocks, {
          [styles.noHeight]: !mainIntro && !highlightedInfo,
        })}
      >
        {(mainIntro || highlightedInfo) && (
          <div className={styles.main}>
            {mainIntro && <p className={styles.mainIntro}>{mainIntro}</p>}
            {highlightedInfo && <HighlightedInfoBlock {...highlightedInfo} />}
          </div>
        )}
        {contactBlockItem && (
          <ContactBlockItem className={styles.aside} {...contactBlockItem} />
        )}
      </div>
      {contentArea && (
        <GridItemOffsetContext.Provider value={2}>
          <ContentArea className={styles.main} {...contentArea} />
        </GridItemOffsetContext.Provider>
      )}
      <div className={styles.fixedBlocks}>
        <div className={styles.main}>
          {program && <AccordionItemBlock {...program} />}
        {contributors && <ContactBlock {...contributors}/>}
        </div>
      </div>
    </main>
  );
};

export default EventPage;
